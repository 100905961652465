import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Platform from './platform/Platform';
import './index.css';



import { createRoot } from "react-dom/client";
import { HashRouter, Route, Routes, Link } from "react-router-dom";


// Initialize Firebase

  createRoot(document.getElementById("root")).render(
    <HashRouter>
            <Routes>
                <Route path="/" element={<Platform />} />
                <Route path="/home" element={<App WebsiteMode="VideoGen" />} />
                <Route path="/platform" element={<Platform />} />
                <Route path="/platform/#generations" element={<Platform />} />
            </Routes>
        </HashRouter>
  );